import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import JackRabbit from "../../images/projects/jack-rabbit/cover.jpg"
import Auris from "../../images/projects/auris/cover.jpg"
import ProjectCard from "../../components/project-card"

const GosunPage = () => (
  <Layout>
    <SEO title="GoSun" />

    <WorkInfo 
      title="GoSun"
      link="gosun.co" 
      team1="Bailey Latimer"
      challenge="
      Born in 2013, GoSun became the first company to bring solar cooking to mainstream consumers. They believed people deserved more accessible solar products, and so began the company's tradition of creating ingenious, functional design with clean aesthetics - quickly becoming a favorite for the off-grid community. They also partnered with Red Cross to serve the third-world market.
      To compete with new and rapidly growing entrants in the outdoor recreation market, GoSun invited me to evolve and reinvent the brand & e- commerce platform for modern-day customers."
      solution="With flexibility and brand expansion in mind, I redesigned their logo & created a design system. I also re-built their Shopify Plus site from top to bottom.
      After the introduction of GoSun's new brand strategy, identity, products, and e-commerce store, their metrics drastically improved; up 300% for conversion rate and down 124% for cart abandonment rate. I was also creative lead for two crowdfunding campaigns (Chill, Flatware) and a crowdsourced equity raise. All reached #1 trending on their respective platforms and raised $1,579,739 total."/>

    <StaticImage class="mb-6" src="../../images/projects/gosun/cover.jpg" alt="GoSun Hero" aspectRatio={16/7} layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/gosun/chill_lifestyle.jpg" alt="GoSun Chill Lifestyle" layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/gosun/chill_screen.jpg" alt="GoSun Chill Mobile Mockup" layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/gosun/favicon_screen.jpg" alt="GoSun Chill Mobile Mockup" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/gosun/chart-dark_mockup.jpg" alt="GoSun Chart Dark" layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/gosun/chart-light_mockup.jpg" alt="GoSun Chart Light" layout={"fullWidth"}/>
      </div>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/gosun/cart-empty_screen.jpg" alt="GoSun Cart Empty" layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/gosun/cart-full_screen.jpg" alt="GoSun Cart Default" layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/gosun/404_screen.jpg" alt="GoSun 404 Screen" layout={"fullWidth"}/>
      <StaticImage class="mb-20"src="../../images/projects/gosun/typography.jpg" alt="GoSun Typography" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="Auris" url="auris"  description="The sound of the past, built for the future." cover={Auris}  role="Design + Development + Branding"/>

          <ProjectCard classes="" title="JackRabbit" url="jackrabbit"  description="Making commuting fun again." cover={JackRabbit}  role="Development"/>
        </ul>
      </div>

  </Layout>
)

export default GosunPage
